<template>
  <div class="row-plat-item-container" ref="container">
    <template v-if="record.type !== 'all'">
      <!-- v-if="record.reportData.advRequest > 0 && record.platId < 900 && record.platId!=5" -->
      <!-- <div class="warn_icon_wrapper">
        <a-icon
          class="warn_icon"
          style=""
          type="alert"
          @click="handleClickWarning(record)"
        />
      </div> -->
    </template>
    <img
      v-if="record.type !== 'all' && record.platId !== 1019 && record.platId <= 10000 && record.icon"
      :src="record.icon"
      style="width: 18px; height: 18px; margin-right: 5px"
      alt=""
    />
    <a-popover
      v-if="record.type !== 'all' && record.type !== 'sdk'"
      @visibleChange="visibleChange"
      :overlayClassName="'tips-container' + record.id"
      placement="bottomLeft"
      trigger="hover"
    >
      <template #content>
        <div class="row-item-content">
          <div class="popover-item">
            <div class="name">广告平台：{{ getPlatName(record.platId) }}</div>
          </div>
          <div class="popover-item" v-if="record.platId === 1019">
            <div class="name">投放计划：{{ record.adPlanName }}</div>
          </div>
          <div class="popover-item" v-if="record.platId !== 1019">
            <div class="name">账号名称：{{ record.platAccountName }}</div>
          </div>
          <div class="popover-item" v-if="record.platId !== 1019">
            <div class="names">应用ID：{{ record.platAppId }}</div>
            <div class="copy"><a-button size="small" type="link" @click="copyId(record.platAppId)">复制</a-button></div>
          </div>

          <div class="popover-item" v-if="record.platId !== 1019">
            <div class="names">{{ platPlaceLabel(record.platId) }}：{{ record.platPlaceId }}</div>
            <div class="copy">
              <a-button size="small" type="link" @click="copyId(record.platPlaceId)">复制</a-button>
            </div>
          </div>
        </div>
      </template>
      <span class="row-plat-item-right">
        {{ record.name }}
      </span>
    </a-popover>
    <span v-else-if="record.type === 'sdk'" class="row-plat-item-right">
      <img style="width:18px" :src="headerIcon" alt="">
      <a-popover
        overlayClassName="sdk-name-popover"
        placement="bottomLeft"
        trigger="hover"
      >
        <template #content>
          <div
            class="text"
            style="width: 550px"
            v-html="`${title} ADX是${title}的自有广告资源，对接了多家直客以及三方DSP预算，为开发者提供更多的广告预算支持。<br/>${title} ADX功能涉及到付款结算事宜，需联系${title}商务或客服沟通确认后进行结算。`"
          >
          </div>
        </template>
        <span style="cursor: pointer">
          {{ title }} ADX
        </span>
      </a-popover>
    </span>
    <span v-else>
      {{ record.name }}
    </span>
    <WarningModal v-if="warning" :visible="warning" @modalCancel="warning = false" :parameters="query" />
  </div>
</template>

<script>
import { copy } from '@/utils/string'
import WarningModal from '../components/warningModal'
import { platPlaceLabel } from '@/utils/autoLabel'
import { mapState } from 'vuex'
export default {
  components: {
    WarningModal
  },
  data () {
    return {
      warning: false
    }
  },
  props: {
    record: {
      default: () => ({}),
      type: Object
    },
    index: {
      default: 0,
      type: Number
    },
    platList: {
      default: () => [],
      type: Array
    },
    query: {
      default: () => ({}),
      type: Object
    }
  },
  computed: {
    ...mapState({
      // 平台名
      title: (state) => state.autoweb.title,
      headerIcon: (state) => state.autoweb.headerIcon
    })
  },
  methods: {
    platPlaceLabel,
    getPlatName (id) {
      return this.platList.find((item) => item.id === id) ? this.platList.find((item) => item.id === id).name : ''
    },
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    visibleChange (e) {},
    handleClickWarning (row) {
      this.query.id = row.id
      this.warning = true
    }
  }
}
</script>

<style lang="less" scoped>
.row-plat-item-container {
  display: flex;
  flex-wrap: nowrap;
  // max-width: 200px;
  position: relative;
  .warn_icon_wrapper {
    position: absolute;
    top: -39px;
    left: -152px;
    width: 40px;
    height: 40px;
    border-left: 20px solid orange;
    border-top: 20px solid rgba(0, 0, 0, 0);
    border-right: 20px solid rgba(0, 0, 0, 0);
    border-bottom: 20px solid rgba(0, 0, 0, 0);
    border-right: none;
    transform: rotate(-135deg);
    .warn_icon {
      cursor: pointer;
      color: #fff;
      margin-right: 5px;
      transform: rotate(135deg);
      width: 12px;
      height: 12px;
      line-height: 18px;
      position: relative;
      top: -3px;
      left: -18px;
      position: absolute;
    }
  }
  .row-plat-item-right {
    display: inline-block;
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }
}
.row-item-content {
  width: 240px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  .popover-item {
    height: 30px;
    line-height: 30px;
    width: 100%;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    .names {
      max-width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>

<style lang="less">
.sdk-name-popover.ant-popover {
  caret-color: transparent;
  .text{
    color: @primary-color;
  }
  .ant-popover-inner {
    border: 1px solid @primary-color;
    background-color: #f1f4ff !important;
    border-radius: 10px;
  }
  .ant-popover-content {
    .ant-popover-arrow {
      background-color: #f0f5ff !important;
      border-left: 1px solid @primary-color;
      border-top: 1px solid @primary-color;
    }
  }
}
</style>
